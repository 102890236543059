import type { SelectedOption } from '@gem/common';
import useEditorStore from '../../common/stores/editor';
import useSectionStore from '../../common/stores/section';
import { cacheGetSectionCidByComponentUid } from '../../common/use-cases/cache';
import { getComponentByUid, getComponentsByTag } from '../../common/utils/section/component';
import type { Component } from '../../common/utils/types';
import { controlChange } from '../use-cases/control';

export const useSyncProductProperties = () => {
  const sectionStore = useSectionStore();
  const editorStore = useEditorStore();
  const editingComponentUid = computed(() => editorStore.getEditingComponentUid ?? '');

  const components = computed(() => {
    const sectionItems = sectionStore.getItems;
    let array: Component[] = [];
    for (let index = 0; index < sectionItems.length; index++) {
      const item = sectionItems[index];
      const newComponents = getComponentsByTag(JSON.parse(item.component ?? '{}'), 'Product', []);
      array = array.concat(newComponents);
    }
    return array;
  });

  const syncCurrentElement = (componentUid = editingComponentUid.value) => {
    const sectionId = cacheGetSectionCidByComponentUid(componentUid);
    const section = sectionStore.getItemByCid(sectionId);
    const sectionItemComponent = JSON.parse(section?.component ?? '{}');
    const currentComponent = getComponentByUid(sectionItemComponent, componentUid);
    const productSetting = currentComponent?.settings?.productSetting;
    const syncElement = components.value.find((item) => {
      return (
        item.uid != componentUid &&
        item.settings?.isSyncProduct &&
        item.settings?.productSetting?.productId == productSetting?.productId
      );
    });
    if (syncElement) {
      const initialVariantId = syncElement.settings?.productSetting.initialVariantId;
      const initialVariantBaseId = syncElement.settings?.productSetting.initialVariantBaseId;
      const newValue = {
        ...productSetting,
        initialVariantId,
        initialVariantBaseId,
      };
      onChangeSettings(sectionId, componentUid, newValue);
    }
  };

  const syncToElements = (
    componentUid: string,
    productId: string,
    initialVariantId: string | undefined,
    initialVariantBaseId: string | undefined,
    selectedOptions?: Pick<SelectedOption, 'value' | 'name' | 'optionType' | 'valueBaseID' | 'nameBaseID'>[],
  ) => {
    components.value.forEach((component) => {
      if (
        component.uid != componentUid &&
        component.settings?.isSyncProduct &&
        component.settings?.productSetting?.productId == productId
      ) {
        const sectionId = cacheGetSectionCidByComponentUid(component.uid);
        const newValue = {
          ...component.settings?.productSetting,
          initialVariantId,
          initialVariantBaseId,
          selectedOptions,
        };
        onChangeSettings(sectionId, component.uid, newValue);
      }
    });
  };

  const onChangeSettings = (sectionId: string, componentUid: string, productSetting: any) => {
    controlChange({
      sectionCid: sectionId,
      settings: [
        {
          componentUid: componentUid,
          screenId: 'desktop',
          hasDevices: false,
          controlId: 'productSetting',
          newValue: productSetting,
          controlType: 'product',
          groupType: 'setting',
        },
      ],
    });
  };

  return {
    syncCurrentElement,
    syncToElements,
  };
};
