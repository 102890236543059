export type History = {
  id: string;
  createdAt: number;
  user: any;
  data: any;
};

function removeFromTo(array: any[], from: number, to: number) {
  array.splice(from, to);
  return array.length;
}

const HistoryManager = function () {
  let commands: History[] = [],
    index = -1,
    limit = 40,
    isExecuting = false,
    isLimited = false;
  const execute = function (command: History, attr: 'undo' | 'redo', options?: Record<string, any>) {
    if (!command?.data || typeof Object(command.data)[attr] !== 'function') {
      return;
    }

    document.dispatchEvent(
      new CustomEvent('dynamic-trigger:undo-redo', {
        bubbles: true,
        detail: {
          command,
          attr,
        },
      }),
    );

    isExecuting = true;
    const data = { ...Object(command.data).data, options };
    Object(command.data)[attr](data);
    isExecuting = false;
    return;
  };

  return {
    /*
      Add a command to the queue.
      */
    add: function (command: History) {
      if (isExecuting) {
        return this;
      }
      // if we are here after having called undo,
      // invalidate items higher on the stack
      commands.splice(index + 1, commands.length - index);
      document.dispatchEvent(
        new CustomEvent('dynamic-trigger:create', {
          bubbles: true,
          detail: {
            command,
          },
        }),
      );
      commands.push(command);

      // if limit is set, remove items from the start
      if (limit && commands.length > limit) {
        isLimited = true;
        removeFromTo(commands, 0, commands.length - limit);
      } else {
        isLimited = false;
      }

      // set the current index to the end
      index = commands.length - 1;
      return this;
    },

    /*
          Perform undo: call the undo function at the current index and decrease the index by 1.
          */
    undo: function (options?: Record<string, any>) {
      const command = commands[index];
      if (!command) {
        return this;
      }
      execute(command, 'undo', options);
      index -= 1;
      return this;
    },

    /*
          Perform redo: call the redo function at the next index and increase the index by 1.
          */
    redo: function (options?: Record<string, any>) {
      const command = commands[index + 1];
      if (!command) {
        return this;
      }
      execute(command, 'redo', options);

      index += 1;
      return this;
    },

    /*
          Clears the memory, losing all stored states. Reset the index.
          */
    clear: function () {
      commands = [];
      index = -1;
    },

    hasUndo: function () {
      return index !== -1;
    },

    hasRedo: function () {
      return index < commands.length - 1;
    },

    getCommands: function () {
      return commands;
    },

    setIndex: function (newIndex: number) {
      index = newIndex;
      return index;
    },
    setHistoryLimited: function (limited: boolean) {
      isLimited = limited;
      return isLimited;
    },

    getIndex: function () {
      return index;
    },

    setLimit: function (l: number) {
      limit = l;
    },

    getIsLimited: function () {
      return isLimited;
    },
  };
};

export default HistoryManager;
