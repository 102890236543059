import type { FilterType, Tab } from '@gem/uikit';
import { defineStore } from 'pinia';
import { DEFAULT_SELECTED_TAB, SORT_SAVED_LIBRARY_ACTIVE_ID } from '../utils/const';
import type {
  LibraryTemplatesQueryResponse,
  LibraryTemplateSelectFragment,
} from '@/api/app/queries/library-template.generated';
import type {
  LibrarySectionSelectFragment,
  LibrarySectionsQueryResponse,
} from '@/api/app/queries/library-sections.generated';
import type { ThemePagesQueryResponse } from '@/api/app/queries/theme-pages.generated';
import type { ShopLibraryPagesQueryResponse } from '@/api/app/queries/sales-funnel/shop-library-pages.generated';

type AnchorType = {
  cid: string | undefined;
  direction: 'before' | 'after';
};

type SectionToSave = {
  uid?: string;
  name?: string;
  isThemeSection?: boolean;
};

type PostPurchaseTemplateToSave = {
  uid?: string;
  name?: string;
};

export type State = {
  isOpen: boolean;
  isSaveToLibrary: boolean;
  isSavingToLibrary: boolean;
  isReplaceFontWithThemeFont: boolean;
  sectionToSave: SectionToSave;
  postPurchaseTemplateToSave: PostPurchaseTemplateToSave;
  anchor: AnchorType;
  filter: FilterType;
  isOpenConfirmModal: boolean;
  selectedTemplateId?: string;
  tempSelectThemeId?: string;
  selectedPageId?: string;
  tempSelectedPageId?: string;
  selectedPostPurchaseId?: string;
  tempSelectedPostPurchaseId?: string;
  clearAllSectionData: boolean;
  categoriesIds?: string[];
  previewItem: LibraryTemplateSelectFragment | LibrarySectionSelectFragment | null;
  tabs: Tab;
  totalThemeSection: number;
  isShowIntroduceThemeSection: boolean;
  allCacheKeyThemeSection: string[];
  isRenamedThemeSection: boolean;
  sectionsGlobalData: LibrarySectionsQueryResponse[] | null;
  themesPageGlobalData: LibraryTemplatesQueryResponse[] | null;
  existingThemePagesData: ThemePagesQueryResponse[] | null;
  savedPostPurchaseData: ShopLibraryPagesQueryResponse[] | null;
  totalPageSection: number | string;
  totalPageTemplate: number | string;
  totalThemePages: number | string;
  totalSavedPostPurchase: number | string;
  isReplaceProduct: boolean;
};

export const useLibraryStore = defineStore('library', {
  state: (): State => ({
    isOpen: false,
    isSaveToLibrary: false,
    isSavingToLibrary: false,
    isReplaceFontWithThemeFont: true,
    sectionToSave: {
      uid: undefined,
      name: undefined,
      isThemeSection: false,
    },
    postPurchaseTemplateToSave: {
      uid: undefined,
      name: undefined,
    },
    anchor: {
      cid: undefined,
      direction: 'after',
    },
    filter: DEFAULT_SELECTED_TAB,
    isOpenConfirmModal: false,
    selectedTemplateId: undefined,
    tempSelectThemeId: undefined,
    selectedPageId: undefined,
    tempSelectedPageId: undefined,
    selectedPostPurchaseId: undefined,
    tempSelectedPostPurchaseId: undefined,
    clearAllSectionData: false,
    categoriesIds: undefined,
    previewItem: null,
    tabs: {
      global: { content: 'Sections', disabled: false },
      templates: { content: 'Templates', disabled: false },
      funnelExisting: { content: 'Existing pages', disabled: false, displayed: false },
      savedLibrary: {
        content: 'Saved Library',
        isShowFilterSort: true,
        sortActive: SORT_SAVED_LIBRARY_ACTIVE_ID,
        disabled: false,
      },
      postPurchase: { content: 'Templates', disabled: false, displayed: false },
      savedPostPurchase: { content: 'Saved Library', disabled: false, displayed: false },
    },
    isShowIntroduceThemeSection: false,
    allCacheKeyThemeSection: [],
    totalThemeSection: 0,
    isRenamedThemeSection: false,
    sectionsGlobalData: null,
    themesPageGlobalData: [],
    existingThemePagesData: [],
    savedPostPurchaseData: [],
    totalPageSection: 0,
    totalPageTemplate: 0,
    totalThemePages: 0,
    totalSavedPostPurchase: 0,
    isReplaceProduct: true,
  }),
  getters: {
    getTabs(state) {
      return state.tabs;
    },
    getActiveTab(state) {
      return state.tabs[state.filter];
    },
    getFilter(state) {
      return state.filter;
    },
    getAnchor(state) {
      return state.anchor;
    },
    getIsOpen(state) {
      return state.isOpen;
    },
    getIsSaveToLibrary(state) {
      return state.isSaveToLibrary;
    },
    getIsSavingToLibrary(state) {
      return state.isSavingToLibrary;
    },
    getCategoriesIds(state) {
      return state.categoriesIds;
    },
    getSectionToSave(state) {
      return state.sectionToSave;
    },
    getPreviewItem(state): LibraryTemplateSelectFragment | LibrarySectionSelectFragment | null {
      return state.previewItem;
    },
    getTotalThemeSection(state) {
      return state.totalThemeSection;
    },
    getIsShowIntroduceThemeSection(state) {
      return state.isShowIntroduceThemeSection;
    },
    getCacheAllKeyThemeSection(state) {
      return state.allCacheKeyThemeSection;
    },
    getLastKeyThemeSection(state) {
      const totalKey = state.allCacheKeyThemeSection.length;
      return totalKey ? state.allCacheKeyThemeSection[totalKey - 1] : '';
    },
    getIsRenamedThemeSection(state) {
      return state.isRenamedThemeSection;
    },
    getSectionsGlobalData(state) {
      return state.sectionsGlobalData;
    },
    getThemesPageGlobalData(state) {
      return state.themesPageGlobalData;
    },
    getExistingThemePagesData(state) {
      return state.existingThemePagesData;
    },
    getSavedPostPurchaseData(state) {
      return state.savedPostPurchaseData;
    },
    getClearAllSectionData(state) {
      return state.clearAllSectionData;
    },
    getTotalPageSection(state) {
      return state.totalPageSection;
    },
    getTotalPageTemplate(state) {
      return state.totalPageTemplate;
    },
    getTotalThemePages(state) {
      return state.totalThemePages;
    },
    getTotalSavedPostPurchase(state) {
      return state.totalSavedPostPurchase;
    },
    getIsReplaceFontWithThemeFont(state) {
      return state.isReplaceFontWithThemeFont;
    },
    getPostPurchaseTemplateToSave(state) {
      return state.postPurchaseTemplateToSave;
    },
    getIsReplaceProduct(state) {
      return state.isReplaceProduct;
    },
  },
  actions: {
    clear() {
      this.$reset();
    },
    resetAnchor() {
      this.anchor.cid = undefined;
      this.anchor.direction = 'after';
    },
    setAnchor({ cid, direction }: AnchorType) {
      this.anchor.cid = cid;
      this.anchor.direction = direction;
    },
    setTabs(tabs: Tab) {
      this.tabs = tabs;
    },
    setIsOpen(value: boolean) {
      this.isOpen = value;
    },
    setIsSaveToLibrary(value: boolean) {
      this.isSaveToLibrary = value;
    },
    setIsReplaceFontWithThemeFont(value: boolean) {
      this.isReplaceFontWithThemeFont = value;
    },
    setIsSavingToLibrary(value: boolean) {
      this.isSavingToLibrary = value;
    },
    setFilter(value: FilterType) {
      this.filter = value;
    },
    setCategoriesIds(value?: string[]) {
      this.categoriesIds = value;
    },
    setSectionUidToSave(value?: string) {
      this.sectionToSave.uid = value;
    },
    setSectionNameToSave(value?: string) {
      this.sectionToSave.name = value;
    },
    setIsSaveThemeSection(value?: boolean) {
      this.sectionToSave.isThemeSection = value;
    },
    setPreviewItem(value: LibraryTemplateSelectFragment | LibrarySectionSelectFragment | null) {
      this.previewItem = value;
    },
    setTotalThemeSection(value: number) {
      this.totalThemeSection = value;
    },
    setIsShowIntroduceThemeSection(value: boolean) {
      this.isShowIntroduceThemeSection = value;
    },
    setCacheAllKeyThemeSection(value: string[]) {
      this.allCacheKeyThemeSection = value;
    },
    pushKeyThemeSectionToCache(value: string) {
      this.allCacheKeyThemeSection.push(value);
    },
    setIsRenamedThemeSection(value: boolean) {
      this.isRenamedThemeSection = value;
    },
    setSectionsGlobalData(value: LibrarySectionsQueryResponse[] | null) {
      this.sectionsGlobalData = value;
    },
    setThemesPageGlobalData(value: LibraryTemplatesQueryResponse[] | null) {
      this.themesPageGlobalData = value;
    },
    setExistingThemePagesData(value: ThemePagesQueryResponse[] | null) {
      this.existingThemePagesData = value;
    },
    setSavedPostPurchaseData(value: ShopLibraryPagesQueryResponse[] | null) {
      this.savedPostPurchaseData = value;
    },
    setClearAllSectionData(value: boolean) {
      this.clearAllSectionData = value;
    },
    setTotalPageSection(value: string | number) {
      this.totalPageSection = value;
    },
    setTotalPageTemplate(value: string | number) {
      this.totalPageTemplate = value;
    },
    setTotalThemePages(value: string | number) {
      this.totalThemePages = value;
    },
    setTotalSavedPostPurchase(value: string | number) {
      this.totalSavedPostPurchase = value;
    },
    setPostPurchaseTemplateToSave(value: PostPurchaseTemplateToSave) {
      this.postPurchaseTemplateToSave = value;
    },
    setIsReplaceProduct(value: boolean) {
      this.isReplaceProduct = value;
    },
  },
});

export default useLibraryStore;
